import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom/client';
import { StyledEngineProvider } from '@mui/material/styles';
import { LoginPage } from './pages/LoginPage';
import { ApiProvider } from './components/ApiProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.scss';

axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ToastContainer />
			<ApiProvider>
				<LoginPage />
			</ApiProvider>
		</StyledEngineProvider>
	</React.StrictMode>,
);

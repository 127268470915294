import React, { FC, HTMLAttributes } from 'react';

export const ShowPassword: FC<HTMLAttributes<SVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" {...props}>
			<path fill="none" d="M0 0h22v22H0z" />
			<path d="M11 4.9c-4.1 0-7.6 2.5-9 6.1 1.4 3.6 4.9 6.1 9 6.1s7.6-2.5 9-6.1c-1.4-3.6-4.9-6.1-9-6.1zm0 10.2c-2.3 0-4.1-1.8-4.1-4.1S8.7 6.9 11 6.9s4.1 1.8 4.1 4.1-1.8 4.1-4.1 4.1zm0-6.6c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
		</svg>
	);
};

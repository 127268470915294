import React, { FC, HTMLAttributes } from 'react';

export const Logo: FC<HTMLAttributes<SVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="183" height="77" viewBox="-7 0 183 77" {...props}>
			<path fill="none" d="M-7 0h183v77H-7z" />
			<path fill="none" d="M0 0h176v77H0V0z" />
			<path
				fill="#212121"
				d="M12.8 23.5v10.9h14.7v8H12.8v11.1h19.1v8.3h-30V15.2h30v8.3H12.8zm69 38.3h-8.4c-.9 0-1.7-.2-2.3-.7-.6-.4-1-1-1.3-1.7L67 51.3H49.4l-2.8 8.1c-.2.6-.7 1.1-1.3 1.6s-1.4.7-2.3.7h-8.4l18-46.6h11.1l18.1 46.7zM63.3 44l-4.4-12.8c-.3-.8-.7-1.8-1-2.9-.4-1.1-.7-2.3-1.1-3.6-.3 1.3-.7 2.5-1 3.6-.4 1.1-.7 2.1-1 2.9L50.5 44h12.8z"
			/>
			<path
				fill="#008AC6"
				d="M113.9 52.9c1.1 0 1.9.3 2.5.9s.9 1.4.9 2.3v5.8H84.6v-3.2c0-.6.1-1.3.4-2s.7-1.3 1.3-1.9l13.4-13.5c1.2-1.2 2.2-2.3 3-3.3.9-1 1.6-2.1 2.1-3.1s1-2 1.2-3c.3-1 .4-2.1.4-3.2 0-1.9-.4-3.3-1.3-4.3s-2.2-1.5-4-1.5c-1.5 0-2.7.4-3.7 1.2-1 .8-1.7 1.7-2.1 2.9-.4 1.2-1 2-1.8 2.3-.7.4-1.8.5-3.1.3l-5.2-.9c.3-2.3 1-4.4 2-6.1s2.2-3.2 3.6-4.3c1.4-1.2 3.1-2 4.9-2.6 1.9-.6 3.9-.8 6-.8 2.3 0 4.4.3 6.2 1 1.8.7 3.4 1.6 4.7 2.8 1.3 1.2 2.3 2.6 3 4.3s1 3.5 1 5.5c0 1.7-.2 3.3-.7 4.7-.5 1.5-1.1 2.8-2 4.1s-1.8 2.6-2.9 3.8-2.3 2.5-3.5 3.7l-9 9.2c1.2-.4 2.3-.6 3.5-.8s2.2-.3 3.2-.3h8.7zm7.9 3.9c0-.8.1-1.5.4-2.1s.7-1.2 1.2-1.7 1.1-.9 1.8-1.2c.7-.3 1.4-.4 2.2-.4s1.5.1 2.2.4c.7.3 1.3.7 1.8 1.2s.9 1.1 1.2 1.7.4 1.4.4 2.1c0 .8-.1 1.5-.4 2.2s-.7 1.3-1.2 1.7c-.5.5-1.1.9-1.8 1.2s-1.4.4-2.2.4-1.6-.1-2.2-.4c-.7-.3-1.3-.7-1.8-1.2s-.9-1.1-1.2-1.7-.4-1.4-.4-2.2zM171 38.5c0 4.1-.4 7.6-1.3 10.6s-2.1 5.5-3.6 7.4c-1.5 2-3.4 3.4-5.5 4.4s-4.4 1.4-6.9 1.4-4.8-.5-6.9-1.4-3.9-2.4-5.5-4.4-2.7-4.4-3.6-7.4c-.9-3-1.3-6.5-1.3-10.6s.4-7.6 1.3-10.6 2.1-5.5 3.6-7.4c1.5-2 3.4-3.4 5.5-4.4s4.4-1.4 6.9-1.4 4.8.5 6.9 1.4 4 2.4 5.5 4.4 2.8 4.4 3.6 7.4 1.3 6.5 1.3 10.6zm-10.3 0c0-3.2-.2-5.9-.6-7.9-.4-2.1-.9-3.7-1.6-4.8-.7-1.2-1.4-2-2.3-2.4-.9-.4-1.7-.7-2.6-.7-.9 0-1.7.2-2.6.7-.8.4-1.6 1.2-2.2 2.4-.7 1.2-1.2 2.8-1.6 4.8-.4 2.1-.6 4.7-.6 7.9s.2 5.9.6 7.9c.4 2 .9 3.7 1.6 4.8.7 1.2 1.4 2 2.2 2.4s1.7.7 2.6.7c.9 0 1.7-.2 2.6-.7.9-.4 1.6-1.2 2.3-2.4s1.2-2.8 1.6-4.8.6-4.7.6-7.9z"
			/>
		</svg>
	);
};

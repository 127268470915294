import { createResponse } from '../service/api/utils';
import { delay } from '../service/promise';
import { ApiMethods } from '../hooks/useCreateApi';

export type LoginParams = {
	username: string;
	password: string;
};
export type LoginResponse = {
	token: string;
};

export const methods = (methods: ApiMethods) => {
	const login = (params: LoginParams) => {
		return methods.post<LoginResponse>('/login', params);
	};

	return {
		login,
	};
};

import React, { FC, HTMLAttributes } from 'react';

export const HidePassword: FC<HTMLAttributes<SVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" {...props}>
			<path fill="none" d="M0 0h22v22H0z" />
			<path d="M11 6.9c2.3 0 4.1 1.8 4.1 4.1 0 .5-.1 1-.3 1.5l2.4 2.4c1.2-1 2.2-2.4 2.8-3.9-1.4-3.6-4.9-6.1-9-6.1-1.2 0-2.2.2-3.3.5l1.8 1.8c.5-.2 1-.3 1.5-.3zM2.8 4.7l1.9 1.9.4.3C3.7 8 2.6 9.4 2 11c1.4 3.6 4.9 6.1 9 6.1 1.3 0 2.5-.2 3.6-.7l.3.3 2.4 2.4 1-1L3.9 3.6 2.8 4.7zm4.5 4.5l1.3 1.3c0 .2-.1.4-.1.5 0 1.4 1.1 2.5 2.5 2.5.2 0 .4 0 .5-.1l1.3 1.3c-.5.3-1.2.4-1.8.4-2.3 0-4.1-1.8-4.1-4.1 0-.7.2-1.3.4-1.8zm3.6-.6l2.6 2.6v-.1c0-1.4-1.1-2.5-2.5-2.5h-.1z" />
		</svg>
	);
};

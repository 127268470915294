import React, { memo, FC } from 'react';
import styles from './index.module.scss';

export interface CircleSpinnerProps {
	color?: string;
}

export const CircleSpinner: FC<CircleSpinnerProps> = memo(({ color = '#fff' }) => {
	return (
		<div className={styles.root} style={{ color }}>
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
			<div />
		</div>
	);
});

CircleSpinner.displayName = 'CircleSpinner';

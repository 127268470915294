import React, { FC } from 'react';
import { Logo } from '../../icons/Logo';
import { MaterialInput } from '../../components/MaterialInput';
import { MaterialButton } from '../../components/MaterialButton';
import { useForm, Controller } from 'react-hook-form';
import styles from './index.module.scss';
import { isString, omit } from 'lodash-es';
import { useApi } from '../../hooks/useApi';
import { isSuccess } from '../../service/actions';
import { parse } from '../../service/query-string';
import { toast } from 'react-toastify';
import cn from 'classnames';
import { getEnvironmentVariable } from '../../service/env';

interface FormValues {
	username: string;
	password: string;
}

export const LoginPage: FC = () => {
	const api = useApi();

	const { control, handleSubmit } = useForm<FormValues>({
		defaultValues: {
			username: '',
			password: '',
		},
		mode: 'onBlur',
	});

	const onSubmit = (values: FormValues) => {
		api.login(values).then((response) => {
			if (isSuccess(response)) {
				const { to } = parse(window.location.search);

				const { token, ...data } = response.body;

				const domain = getEnvironmentVariable('REACT_APP_DOMAIN', '.e-assessment.rndlab.ru');

				// TODO: временное решение, пока сервер не возвращает куку
				document.cookie = `auth_token=${token};domain=${domain}`;
				document.cookie = `auth_data=${JSON.stringify(data)};domain=${domain}`;

				const redirect = to || process.env.REACT_APP_REDIRECT;
				if (isString(redirect)) {
					window.location.replace(redirect);
				}
			} else {
				toast.error('Вы ввели неверное имя пользователя или пароль');
			}
		});
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.topStretcher} />
			<Logo className={styles.logo} />
			<div className={styles.title}>Система управления профессиональной квалификацией</div>
			<div className={styles.titleStretcher} />
			<div className={styles.column}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.content}>
						<div className={styles.loginTitle}> Вход </div>
						<Controller
							name="username"
							control={control}
							rules={{ required: true }}
							render={({ field, fieldState }) => (
								<MaterialInput
									className={styles.input}
									placeholder={'Логин'}
									invalid={!!fieldState.error}
									{...omit(field, 'ref')}
								/>
							)}
						/>
						<Controller
							name="password"
							control={control}
							rules={{ required: true }}
							render={({ field, fieldState }) => (
								<MaterialInput
									className={styles.input}
									placeholder={'Пароль'}
									invalid={!!fieldState.error}
									type={'password'}
									{...omit(field, 'ref')}
								/>
							)}
						/>
						<MaterialButton className={styles.button} type={'submit'} size={'large'} color="blue">
							Войти
						</MaterialButton>
					</div>
				</form>
			</div>
			<div className={styles.bottomStretcher} />
			<div className={cn(styles.copyright, styles.column)}>© Expert Me LLC</div>
		</div>
	);
};

import { isNil } from 'lodash-es';

export function getEnvironmentVariable(name: string, fallback?: string) {
	const variable = process.env[name];

	if (!isNil(variable)) {
		return variable;
	} else {
		if (isNil(fallback)) {
			throw new Error(`Значение переменной окружения для '${name}' не определено.`);
		} else {
			return fallback;
		}
	}
}

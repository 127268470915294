import { get, isObject, isString } from 'lodash-es';

export type Action<T = unknown> = SuccessfulAction<T> | FailedAction | CanceledAction;

class Success {
	type = 'success' as const;
}

class Failure {
	type = 'failure' as const;
}

class Cancellation {
	type = 'cancellation' as const;
}

export class SuccessfulAction<T> extends Success {
	body: T;
	constructor(body: T) {
		super();
		this.body = body;
	}
}

export class FailedAction extends Failure {
	message?: string;
	constructor(message?: string) {
		super();
		this.message = message;
	}
}

export class CanceledAction extends Cancellation {}

export function isFailure(response: unknown): response is Failure {
	return response instanceof Failure;
}

export function isSuccess(response: unknown): response is Success {
	return response instanceof Success;
}

export function createSuccessfulAction(): SuccessfulAction<undefined>;
export function createSuccessfulAction<T>(body: T): SuccessfulAction<T>;
export function createSuccessfulAction<T>(body?: T) {
	return new SuccessfulAction(body);
}

export function createCanceledAction() {
	return new CanceledAction();
}

export function createFailedAction(message?: string) {
	return new FailedAction(message);
}

export function throwIfUnsuccessful<Result>(
	promise: PromiseLike<FailedAction | SuccessfulAction<Result> | CanceledAction>,
) {
	return promise.then((response) => {
		if (!isSuccess(response)) {
			throw response;
		} else {
			return response;
		}
	});
}

export function throwIfFailed<Result>(promise: PromiseLike<FailedAction | SuccessfulAction<Result> | CanceledAction>) {
	return promise.then((response) => {
		if (isFailure(response)) {
			throw response;
		} else {
			return response;
		}
	});
}

export function hasMessage(e: unknown): e is { message: string } {
	return isObject(e) && isString(get(e, 'message'));
}

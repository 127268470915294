import React, { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

export interface HideableContentProps extends PropsWithChildren {
	visible?: boolean;
	className?: string;
}

export const HideableContent: FC<HideableContentProps> = ({ visible, children, className }) => {
	return <div className={cn(className, styles.root, visible && styles.root_visible)}> {children} </div>;
};

import React, { CSSProperties, FC, MouseEvent, PropsWithChildren } from 'react';
import cn from 'classnames';
import Button from '@mui/material/Button';
import { CircleSpinner } from '../CircleSpinner';
import { HideableContent } from '../HideableContent';
import styles from './index.module.scss';

interface MaterialButtonProps extends PropsWithChildren {
	color?: 'blue' | 'transparentBlue' | 'red';
	size?: 'medium' | 'large';
	className?: string;
	disabled?: boolean;
	onClick?: (e: MouseEvent) => void;
	type?: 'button' | 'reset' | 'submit';
	style?: CSSProperties;
	loading?: boolean;
}

export const MaterialButton: FC<MaterialButtonProps> = ({
	onClick,
	color = 'transparentBlue',
	size = 'medium',
	disabled = false,
	className = '',
	type,
	style,
	children,
	loading,
}) => {
	return (
		<Button
			className={cn(className, styles.button, styles[`button_${size}`], styles[`button_${color}`])}
			disabled={disabled}
			onClick={onClick}
			type={type}
			style={style}
		>
			<HideableContent className={styles.spinWrap} visible={loading}>
				<CircleSpinner />
			</HideableContent>
			<HideableContent visible={!loading}>{children}</HideableContent>
		</Button>
	);
};

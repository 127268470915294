// The successful response
import { CanceledAction, FailedAction, SuccessfulAction } from '../actions';
import { PromiseResult } from '../../types/utils';

export interface ApiResponseParams<R = unknown> {
	body: R;
	status?: number;
}

export class ApiSuccess<R = unknown> extends SuccessfulAction<R> {
	status?: number;

	constructor(params: ApiResponseParams<R>) {
		super(params.body);
		this.status = params.status;
	}
}

// The logical expected error
export interface ApiErrorParams<E = unknown> {
	body: E;
	message?: string;
	status?: number;
	code?: string;
}
export class ApiError<E = unknown> extends FailedAction {
	body: E;
	message: string;
	status?: number;
	code?: string;

	constructor(params: ApiErrorParams<E>) {
		super();
		this.body = params.body;
		this.message = params.message || '';
		this.status = params.status;
		this.code = params.code;
	}
}

// Some sort of unexpected error
export interface ApiCrashParams {
	message?: string;
	status?: number;
	code?: string;
}

export class ApiCrash extends FailedAction {
	status?: number;

	message?: string;

	code?: string;

	constructor(params: ApiCrashParams) {
		super();
		this.message = params.message;
		this.status = params.status;
		this.code = params.code;
	}
}

// Canceled request
export class ApiCancellation extends CanceledAction {}

export type ApiResponse<Response = unknown, Error = unknown> =
	| ApiSuccess<Response>
	| ApiError<Error>
	| ApiCrash
	| ApiCancellation;

export type ApiCall<T extends (...args: never) => never> = PromiseResult<ReturnType<T>>;

export type ApiParams<T> = T extends (...args: infer V) => never ? V : never;

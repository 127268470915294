import queryString from 'query-string';

const stringifyOptions: queryString.StringifyOptions = {
	encode: true,
	arrayFormat: 'comma',
};

export function stringify(object: Record<string, unknown>) {
	return queryString.stringify(object, stringifyOptions);
}

export function parse<T = Record<string, unknown>>(url: string, options: queryString.ParseOptions = {}): T {
	return queryString.parse(url, {
		arrayFormat: 'comma',
		parseNumbers: false,
		...options,
	}) as unknown as T;
}

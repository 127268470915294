import React, { createContext, FC, PropsWithChildren } from 'react';
import { useCreateApi } from '../../hooks/useCreateApi';
import { methods } from '../../api/methods';
import { getEnvironmentVariable } from '../../service/env';

export const ApiContext = createContext<ReturnType<typeof methods> | undefined>(undefined);

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
	const api = useCreateApi(
		{
			baseURL: getEnvironmentVariable('REACT_APP_BASE_URL', '/api'),
		},
		methods,
	);
	return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

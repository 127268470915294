import { useContext } from 'react';
import { ApiContext } from '../components/ApiProvider';

export function useApi() {
	const api = useContext(ApiContext);

	if (!api) {
		throw Error(
			'Хук `useApi` должен вызываться только внутри ApiProvider. Убедитесь, что он используется в корневом файле проекта.',
		);
	}

	return api;
}

import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { AxiosRequestConfig } from 'axios';
import { ApiRequest, ApiRequestSettings } from '../service/api';
import { stringify } from '../service/query-string';

interface CreateApiOptions {
	baseURL: string;
	token?: string;
}

export interface ApiMethods {
	get<Result = unknown, Error = unknown>(
		url: string,
		params?: Record<string, any>,
		parameters?: AxiosRequestConfig & ApiRequestSettings,
	): ApiRequest<Result, Error>;
	post<Result = unknown, Error = unknown>(
		url: string,
		data: unknown,
		parameters?: AxiosRequestConfig & ApiRequestSettings,
	): ApiRequest<Result, Error>;
	patch<Result = unknown, Error = unknown>(
		url: string,
		data: unknown,
		parameters?: AxiosRequestConfig & ApiRequestSettings,
	): ApiRequest<Result, Error>;
	delete<Result = unknown, Error = unknown>(
		url: string,
		data?: unknown,
		parameters?: AxiosRequestConfig & ApiRequestSettings,
	): ApiRequest<Result, Error>;
}

export type CreateApiMethods<T> = (object: ApiMethods) => T;

export function useCreateApi<Methods>(options: CreateApiOptions, methods: CreateApiMethods<Methods>) {
	const { token, baseURL } = options;

	const config = useMemo(
		() => ({
			headers: {
				Authorization: `Bearer sso_1.0_${token}`,
			},
		}),
		[token],
	);

	const getMethod = useCallback(
		<Result = unknown, Error = unknown>(
			url: string,
			params?: Record<string, unknown>,
			settings?: AxiosRequestConfig & ApiRequestSettings,
		) => {
			const fullUrl = params && !isEmpty(params) ? `${baseURL}${url}?${stringify(params)}` : `${baseURL}${url}`;

			return new ApiRequest<Result, Error>({
				method: 'GET',
				url: fullUrl,
				...config,
				...settings,
			});
		},
		[config, baseURL],
	);

	const postMethod = useCallback(
		<Result = unknown, Error = unknown>(
			url: string,
			data: unknown,
			settings?: AxiosRequestConfig & ApiRequestSettings,
		) => {
			return new ApiRequest<Result, Error>({
				method: 'POST',
				url: `${baseURL}${url}`,
				data,
				...config,
				...settings,
			});
		},
		[config, baseURL],
	);

	const patchMethod = useCallback(
		<Result = unknown, Error = unknown>(
			url: string,
			data: unknown,
			settings?: AxiosRequestConfig & ApiRequestSettings,
		) => {
			return new ApiRequest<Result, Error>({
				method: 'PATCH',
				url: `${baseURL}${url}`,
				data,
				...config,
				...settings,
			});
		},
		[config, baseURL],
	);

	const deleteMethod = useCallback(
		<Result = unknown, Error = unknown>(
			url: string,
			data?: unknown,
			settings?: AxiosRequestConfig & ApiRequestSettings,
		) => {
			return new ApiRequest<Result, Error>({
				method: 'DELETE',
				url: `${baseURL}${url}`,
				data,
				...config,
				...settings,
			});
		},
		[config, baseURL],
	);

	return useMemo(() => {
		return methods({ get: getMethod, post: postMethod, patch: patchMethod, delete: deleteMethod });
	}, [getMethod, postMethod, patchMethod, deleteMethod]);
}

import React, { FC, useCallback, useRef, useState } from 'react';
import { isNumber } from 'lodash-es';
import cn from 'classnames';
import { ShowPassword } from '../../icons/ShowPassword';
import { HidePassword } from '../../icons/HidePassword';
import styles from './index.module.scss';

export interface MaterialInputProps {
	type?: 'text' | 'password' | 'tel' | string;
	placeholder?: string;
	className?: string;
	value?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	onClick?: () => void;
	name?: string;
	autocomplete?: boolean;
	readOnly?: boolean;
	disabled?: boolean;
	decimal?: boolean;
	maxLength?: number;
	min?: number;
	max?: number;
	invalid?: boolean;
}

export const MaterialInput: FC<MaterialInputProps> = ({
	type = 'text',
	placeholder = '',
	className,
	value,
	disabled,
	onBlur,
	onChange,
	readOnly,
	min,
	max,
	decimal,
	maxLength,
	invalid,
	...rest
}) => {
	const [innerType, setInnerType] = useState(type === 'number' ? 'text' : type);
	const [focus, setFocus] = useState(false);
	const input = useRef<HTMLInputElement>(null);

	const onFocusInternal = () => {
		setFocus(true);
	};

	const onBlurInternal = (event: React.FocusEvent<HTMLInputElement>) => {
		onBlur?.(event);
		setFocus(false);
	};

	const onChangeNew = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (type === 'number' && !decimal && event.target) {
			const numericValue = Number(event.target.value);
			if (min && numericValue < min) {
				event.target.value = String(min);
			} else if (max && numericValue > max) {
				event.target.value = String(max);
			}
		}

		if (type === 'number' || decimal) {
			event.target.value = event.target.value
				.replace(decimal ? /[^0-9.]/g : /[^0-9]/g, '')
				.replace(/[.]{1,3}/, '.');

			const splitValue = event.target.value.split('.');

			if (!splitValue[0] && splitValue[1]) {
				event.target.value = `0.${splitValue[1]}`;
			}

			const inputValue = event.target.value;

			if (min && +inputValue < min) {
				event.target.value = String(min);
			} else if (max && +inputValue > max) {
				event.target.value = String(max);
			}
		}

		if (maxLength && String(event.target.value).length > maxLength && input.current) {
			input.current.value = input.current.value.substr(0, maxLength);
			event.target.value = input.current.value.substr(0, maxLength);
		}

		onChange && onChange(event);
	};

	const onAnimationStart = (e: React.AnimationEvent) => {
		if (e.animationName.indexOf('onAutoFillStart') !== -1) {
			setFocus(true);
		} else if (e.animationName.indexOf('onAutoFillCancel') !== -1) {
			setFocus(false);
		}
	};

	const onPlaceholderClick = useCallback(() => {
		!disabled && input.current && input.current.focus();
	}, [disabled]);

	const onSetInnerType = useCallback(
		(newType: 'text' | 'password') => () => {
			setInnerType(newType);
		},
		[],
	);

	const isFocused = focus || isNumber(value) || (!!value && !!value.length);

	return (
		<div className={cn(className, styles.inputContainer)}>
			<div
				className={cn(styles.placeholder, {
					[styles.placeholderFocus]: isFocused,
					[styles.placeholderInvalid]: invalid,
				})}
				onClick={onPlaceholderClick}
			>
				{placeholder}
			</div>
			<input
				className={cn(styles.customInput, {
					[styles.customInputLighten]: readOnly,
					[styles.customInputInvalid]: invalid,
					[styles.customInputWithIcon]: type === 'password',
				})}
				ref={input}
				type={innerType}
				placeholder={''}
				onFocus={onFocusInternal}
				onBlur={onBlurInternal}
				value={value}
				readOnly={readOnly}
				step={'any'}
				onChange={onChangeNew}
				onAnimationStart={onAnimationStart}
				{...rest}
			/>
			{type === 'password' ? (
				<div className={styles.iconContainer}>
					{innerType === 'password' ? (
						<ShowPassword onClick={onSetInnerType('text')} />
					) : (
						<HidePassword onClick={onSetInnerType('password')} />
					)}
				</div>
			) : null}
		</div>
	);
};

import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { ApiCancellation, ApiCrash, ApiError, ApiResponse, ApiSuccess } from './entities';
import { isFunction } from 'lodash-es';
import { delay } from '../promise';

function getErrorMessage(error: any) {
	return error?.response?.data;
}

function isExpectedError(data: any) {
	return !!data.error;
}

export interface ApiRequestSettings {
	delay?: number;
}

export class ApiRequest<Result = unknown, Error = unknown> {
	private request: Promise<ApiResponse<Result, Error>>;

	private canceler?: Canceler;

	private canceled?: boolean;

	constructor(params: AxiosRequestConfig & ApiRequestSettings) {
		this.request = delay(params.delay || 0).then(() => {
			if (!this.canceled) {
				return axios({
					...params,
					cancelToken: new axios.CancelToken((c) => {
						this.canceler = c;
					}),
				})
					.then((response) => {
						const { data, status } = response;
						if (!this.canceled) {
							if (isExpectedError(data)) {
								const { body, message, code } = data.error;
								return new ApiError({
									body,
									message,
									code,
									status,
								});
							} else {
								return new ApiSuccess({
									body: data,
									status,
								});
							}
						} else {
							return new ApiCancellation();
						}
					})
					.catch((error) => {
						if (!this.canceled) {
							const status = error?.response?.status;

							let message;
							if (error?.response) {
								message = getErrorMessage(error);
							} else if (error.request) {
								message = 'Нет ответа. Проверьте интернет-соединение';
							} else {
								message = 'Не удалось отправить запрос';
							}

							return new ApiCrash({
								message,
								status,
							});
						} else {
							return new ApiCancellation();
						}
					});
			} else {
				return new ApiCancellation();
			}
		});
	}
	then<T>(onfulfilled: (value: ApiResponse<Result, Error>) => T | PromiseLike<T>) {
		return this.request.then((response) => {
			return isFunction(onfulfilled) ? onfulfilled(response) : onfulfilled;
		});
	}
	cancel() {
		this.canceled = true;
		if (this.canceler) {
			this.canceler();
		}
	}
}

export class ApiCanceledRequest {
	private request: Promise<ApiCancellation>;

	private canceled: boolean;

	constructor() {
		this.canceled = true;
		this.request = Promise.resolve(new ApiCancellation());
	}

	then<T>(onfulfilled?: (value: ApiCancellation) => T | PromiseLike<T>) {
		return this.request.then((response) => {
			return isFunction(onfulfilled) ? onfulfilled(response) : onfulfilled;
		});
	}
	cancel() {
		this.canceled = true;
	}
}
